import React from "react";
import { Button, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../theme.js";
import ManualeBsConad from "../ManualeBSConad.pdf";

const styles = {
  buttonStyle: {
    backgroundColor: "transparent",
    color: "white",
    border: "1px solid #ffffff",
    margin: 10,
    borderRadius: 30,
    width: 200,
  },
};

export default function Footer() {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          position: "static",
          bottom: 0,
          width: "100%",
          background: "linear-gradient(to right,#ff4600,#f7a616,#ff4600)",
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap", // Aggiungi questa proprietà
          gap: 10,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography
            variant="body1"
            style={{
              fontSize: "10",
              color: theme.palette.secondary.main,
              alignSelf: "center",
            }}
          >
            Copyright© BIORSAF SRL Innovativa
          </Typography>
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <Button
            href="https://web-conad.biorsaf.it/condizioni-generali-uso-BS-CONAD.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.buttonStyle}
          >
            Condizioni D'uso
          </Button>
          <span>&nbsp;&nbsp;</span>
          <Button
            href="https://biorsaf.it/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.buttonStyle}
          >
            Privacy Policy
          </Button>
          <span>&nbsp;&nbsp;</span>
          <Button
            href="https://biorsaf.it/cookie-policy/"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.buttonStyle}
          >
            Cookie policy
          </Button>
          <span>&nbsp;&nbsp;</span>
          <Button
            href={ManualeBsConad}
            target="_blank"
            rel="noopener noreferrer"
            style={styles.buttonStyle}
          >
            Guida Piattaforma
          </Button>
          <span>&nbsp;&nbsp;</span>
          <Button
            href="https://bit.ly/bs-conad-ticket"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.buttonStyle}
          >
            Segnalazioni
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
}
